import React from 'react';
import Search, {type SearchProps} from '../../../react4xp/components/Search';


interface FpIntroProps {
	search: SearchProps
}


export default function FpIntro(props: FpIntroProps) {
	return (
		<div className="fp-intro">
		<header className="fp-intro__header">
			<h1 className="fp-intro__heading">
			<span className="fp-intro__heading--main">Apps, starter kits and libs for Enonic </span>
			<span className="fp-intro__heading--main-xp">
				<span className="fp-intro__heading--main-xp-text">XP</span>
			</span>
			</h1>
		</header>
		<Search {...props.search} />
		<a className="fp-intro__xp-link" href="https://enonic.com/developer-tour">Get started with Enonic XP, download free software now</a>
		</div>
	);
}
